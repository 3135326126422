import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { classNames } from 'helpers/classNames';
import { showAlert } from 'redux/actions/setAppActions';
import { reactSelectStyles } from 'static/styles/reactSelectStyles';
import { useDebounce } from 'hooks/useDebounce';
import { fetchDadataRegions } from 'api/regions/fetchDadataRegions';
import { createRegion } from 'api/regions/createRegion';
import styles from './AddNewRegionModal.module.scss';

export const AddNewRegionModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [input, setInput] = useState('');
    const [prefixInput, setPrefixInput] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsList, setOptionsList] = useState([]);
    const debouncedText = useDebounce(input, 500);
    const { mutate } = useMutation({
        mutationFn: (data) => createRegion(data),
        onSuccess: () => {
            queryClient.refetchQueries(['regions']);
            onClose();
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    const setRegionsList = async () => {
        const locationsList = await fetchDadataRegions(debouncedText);

        if (locationsList?.suggestions) {
            const result = locationsList.suggestions
                .map(({ data: { region_with_type } }) => {
                    if (!region_with_type) return null;

                    return {
                        value: region_with_type,
                        label: region_with_type
                    };
                })
                .filter((data) => Boolean(data));

            if (result) {
                setOptionsList(result);
            }
        }
    };

    useEffect(() => {
        if (debouncedText.trim() !== '') {
            setRegionsList(debouncedText);
        } else {
            setOptionsList([]);
        }
    }, [debouncedText]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelect = (e) => {
        setSelectedOption(e);
    };

    const handleSave = () => {
        if (selectedOption?.value && prefixInput.trim()) {
            mutate({
                name: selectedOption.value,
                institutionPrefix: prefixInput.trim()
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <Select
                value={selectedOption}
                onChange={handleSelect}
                onInputChange={(e) => setInput(e)}
                options={optionsList}
                placeholder="Выберите регион"
                noOptionsMessage={() =>
                    debouncedText.length > 1
                        ? 'Регионы не найдены'
                        : 'Начните вводить название региона'
                }
                styles={reactSelectStyles}
            />

            <label
                htmlFor="institution-prefix"
                className={classNames(['label', styles.label])}
            >
                Аббревиатура региона (префикс учреждений)
            </label>
            <input
                className={classNames(['input', styles.input])}
                id="institution-prefix"
                type="text"
                name="institution-prefix"
                value={prefixInput}
                onChange={(e) => setPrefixInput(e.target.value)}
            />

            <div className={styles.buttons} onClick={handleSave}>
                <button className="button">Добавить</button>
            </div>
        </div>
    );
};
