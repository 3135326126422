import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

/**
 * @param {String} regionID
 * @param {File} file - Excel file (.xls or .xlsx)
 */
export function uploadInstitutionsExcel(regionID, file) {
    return catchAsync(async () => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(
            `institutions/${regionID}/upload-excel`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    }, 'Ошибка загрузки файла');
}
