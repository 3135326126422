import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import { Alert } from 'components/Alert';
import styles from './MainLayout.module.scss';

export const MainLayout = ({ children }) => {
    const { alert, loading } = useSelector((state) => state.app);

    return (
        <div>
            {!!alert && <Alert text={alert.text} type={alert.type} />}

            <div className={styles.loader}>
                {loading && (
                    <div>
                        <Loader />
                    </div>
                )}
            </div>

            {children}
        </div>
    );
};
