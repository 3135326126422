import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {String} _id
 * @param {String} note
 * @param {Function} saveNote
 */
export const PsychologistsItemDetailNote = ({ _id, note, saveNote }) => {
    const [noteState, setNoteState] = useState('');

    useEffect(() => {
        setNoteState(note);
    }, [note]);

    const changeNoteHandler = (e) => {
        setNoteState(e.target.value);
    };

    const saveNoteHandler = () => {
        saveNote(_id, noteState);
    };

    return (
        <div className={styles.note}>
            <span>Заметка:</span>
            <div>
                <TextareaAutosize
                    className="textarea-autosize"
                    value={noteState}
                    onChange={changeNoteHandler}
                    name="note"
                    placeholder="Введите текст заметки (опционально)"
                    minRows={3}
                />
            </div>
            <div className={styles.noteButtons}>
                <button className="button" onClick={saveNoteHandler}>
                    Сохранить
                </button>
            </div>
        </div>
    );
};
