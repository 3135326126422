import { ContentLayout } from 'components/layouts/ContentLayout';
import { RegionalInstitutionsList } from 'components/regional/institutions/RegionalInstitutionsList';

export const RegionalInstitutionsListPage = () => {
    return (
        <ContentLayout title="Региональные интеграции / Учреждения">
            <RegionalInstitutionsList />
        </ContentLayout>
    );
};
