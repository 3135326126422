const token = process.env.REACT_APP_DADATA_API_KEY;

export const fetchDadataRegions = async (query) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${token}`
        },
        body: JSON.stringify({
            query,
            from_bound: { value: 'region' },
            to_bound: { value: 'region' },
            restrict_value: true
        })
    };

    try {
        const response = await fetch(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
            options
        );

        return response.json();
    } catch (err) {
        console.log(err);
        return null;
    }
};
