import deleteIcon from 'static/img/delete.svg';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {Function} onDelete
 */
export const RegionalMunicipalitiesItem = ({ item, onDelete }) => {
    const { _id, name } = item;

    return (
        <li className={styles.item}>
            <div className={styles.header}>
                <div className={styles.name}>{name}</div>

                <div className={styles.flexRow}>
                    <button className="button-icon">
                        <img
                            src={deleteIcon}
                            alt="Удалить"
                            style={{ width: 15, height: 15, opacity: 0.7 }}
                            onClick={() => onDelete(_id)}
                        />
                    </button>
                </div>
            </div>
        </li>
    );
};
