import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

/**
 * @param {string} regionID
 */
export function fetchInstitutionsByRegionID(regionID) {
    return catchAsync(async () => {
        const response = await axios.get(`institutions/${regionID}`);
        return response.data;
    });
}
