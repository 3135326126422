import { useQuery } from '@tanstack/react-query';

import { fetchMunicipalitiesByRegionID } from 'api/municipalities/fetchMunicipalitiesByRegionID';

/**
 * @param {string} regionID
 */
export function useFetchMunicipalitiesByRegionID(regionID) {
    const { data, error, isLoading } = useQuery({
        queryKey: ['municipalities', regionID],
        queryFn: () => fetchMunicipalitiesByRegionID(regionID)
    });

    return {
        municipalities: data,
        municipalitiesError: error,
        municipalitiesIsLoading: isLoading
    };
}
