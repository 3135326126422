import { ContentLayout } from 'components/layouts/ContentLayout';
import { RegionalRegionsList } from 'components/regional/regions/RegionalRegionsList';

export const RegionalRegionsListPage = () => {
    return (
        <ContentLayout title="Региональные интеграции / Регионы">
            <RegionalRegionsList />
        </ContentLayout>
    );
};
