import { classNames } from 'helpers/classNames';
import editIcon from 'static/img/edit.svg';
import deleteIcon from 'static/img/delete.svg';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.institutionID
 * @param {Object} item.municipality
 * @param {String} item.municipality._id
 * @param {String} item.municipality.name
 * @param {Function} onEdit
 * @param {Function} onDelete
 */
export const RegionalInstitutionsItem = ({ item, onEdit, onDelete }) => {
    const { _id, name, institutionID, municipality } = item;

    return (
        <li
            className={classNames(styles.item, {
                [styles.warnItem]: !municipality
            })}
        >
            <div className={styles.header}>
                <div className={styles.nameWithWrap}>
                    <div>{name}</div>
                    <div>
                        <span>Идентификатор:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {institutionID}
                        </span>
                    </div>
                    <div>
                        <span
                            className={classNames('', {
                                [styles.warnText]: !municipality
                            })}
                        >
                            Муниципалитет:
                        </span>{' '}
                        <span className={styles.letterSpacing}>
                            {municipality ? municipality.name : '-'}
                        </span>
                    </div>
                </div>

                <div className={styles.flexRow}>
                    <button className="button-icon">
                        <img
                            src={editIcon}
                            alt="Редактировать"
                            style={{ width: 15, height: 15, opacity: 0.6 }}
                            onClick={() => onEdit(item)}
                        />
                    </button>
                    <button className="button-icon">
                        <img
                            src={deleteIcon}
                            alt="Удалить"
                            style={{ width: 15, height: 15, opacity: 0.7 }}
                            onClick={() => onDelete(_id)}
                        />
                    </button>
                </div>
            </div>
        </li>
    );
};
