export const reactSelectStyles = {
    control: (base) => ({
        ...base,
        padding: '3px',
        backgroundColor: '#f7f9ff',
        borderRadius: '8px',
        border: '1px solid rgba(18, 36, 67, 0.2)',
        letterSpacing: '0.3px',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid rgba(18, 36, 67, 0.2)'
        }
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        fontStyle: 'italic'
    })
};
