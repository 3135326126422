export async function catchAsync(
    fn,
    defaultMessage = 'Ошибка загрузки данных'
) {
    try {
        return await fn();
    } catch (e) {
        const message = e.response?.data?.message || defaultMessage;

        // eslint-disable-next-line no-throw-literal
        throw { message };
    }
}
