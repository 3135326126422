import { ContentLayout } from 'components/layouts/ContentLayout';
import { RegionalMunicipalitiesList } from 'components/regional/municipalities/RegionalMunicipalitiesList';

export const RegionalMunicipalitiesListPage = () => {
    return (
        <ContentLayout title="Региональные интеграции / Муниципалитеты">
            <RegionalMunicipalitiesList />
        </ContentLayout>
    );
};
