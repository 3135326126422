import React from 'react';

import { classNames } from 'helpers/classNames';
import styles from './ListPagesBlock.module.scss';

export const ListPagesBlock = ({ pagesCount, selectPage, activePage }) => {
    const onClickHandler = (page) => {
        selectPage(page);
    };

    return (
        <div className={styles.wrapper}>
            {Array.from({ length: pagesCount }, (_, i) => (
                <button
                    className={classNames('button', {
                        [styles.active]: activePage === i
                    })}
                    onClick={() => onClickHandler(i)}
                    key={i}
                >
                    {i + 1}
                </button>
            ))}
        </div>
    );
};
