import { getDateTime } from 'helpers/dates';
import { classNames } from 'helpers/classNames';
import { PsychologistsItemStatus } from './PsychologistsItemStatus';
import { RegionalPsychologistsItemDetail } from './RegionalPsychologistsItemDetail';
import dnevnikLogo from 'static/img/dnevnik_logotype.png';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.surname
 * @param {String} item.patronymic
 * @param {String} item.email
 * @param {String} item.login
 * @param {String} item.status
 * @param {String} item.region
 * @param {String} item.city
 * @param {String} item.phone
 * @param {String} item.institution
 * @param {String} item.institutionID
 * @param {Boolean} item.isInstitutionNotFound
 * @param {String} [item.municipality]
 * @param {String} item.regionID
 * @param {String} item.birthday
 * @param {String} item.lastLogin
 * @param {String} item.note
 * @param {String} [item.dnevnikruPersonId]
 * @param {Date} item.createDate
 * @param {Date} item.updateDate
 * @param {String} activeItem
 * @param {Function} activeItemHandler
 * @param {Function} saveNote
 * @param {String} source
 */

export const RegionalPsychologistsItem = ({
    item,
    activeItem,
    activeItemHandler,
    saveNote,
    source = 'testu'
}) => {
    const {
        _id,
        name,
        surname,
        patronymic,
        status,
        institutionID,
        municipality,
        dnevnikruPersonId,
        createDate,
        updateDate
    } = item;
    const isActive = activeItem === _id;
    const isWarning = !institutionID || !municipality;

    const onClickHandler = () => {
        activeItemHandler(_id);
    };

    return (
        <li
            className={classNames(styles.item, {
                [styles.warnItem]: isWarning
            })}
        >
            <div className={styles.header}>
                <div
                    className={classNames(styles.name, {
                        [styles.warnText]: isWarning
                    })}
                >
                    <div>
                        {surname} {name} {patronymic}
                    </div>
                    <div>
                        <span>Заявка создана:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {getDateTime(createDate)}
                        </span>
                    </div>
                    <div>
                        <span>Заявка обновлена:</span>{' '}
                        <span className={styles.letterSpacing}>
                            {getDateTime(updateDate)}
                        </span>
                    </div>
                </div>

                {dnevnikruPersonId && (
                    <img
                        src={dnevnikLogo}
                        width={70}
                        style={{ alignSelf: 'flex-start', marginTop: 10 }}
                        alt="Дневник.ру"
                    />
                )}

                <PsychologistsItemStatus status={status} />
                <div>
                    <button className="button" onClick={onClickHandler}>
                        Подробнее
                    </button>
                </div>
            </div>

            {isActive && (
                <RegionalPsychologistsItemDetail
                    item={item}
                    saveNote={saveNote}
                    source={source}
                />
            )}
        </li>
    );
};
