import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { deleteRegion } from 'api/regions/deleteRegion';
import { useFetchRegions } from 'hooks/useQuery/useFetchRegions';
import { ListPagesBlock } from 'components/ListPagesBlock';
import { SearchBlock } from 'components/common/SearchBlock';
import { RegionalRegionsItem } from './RegionalRegionsItem';
import { Loader } from 'components/Loader';
import { AddNewRegion } from './AddNewRegion';
import { showAlert } from 'redux/actions/setAppActions';

export const RegionalRegionsList = () => {
    const { regions, regionsIsLoading, regionsError } = useFetchRegions();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const [slicedList, setSlicedList] = useState([]);
    const [filterRegex, setFilterRegex] = useState('');
    const [pagesCount, setPagesCount] = useState(1);
    const [page, setPage] = useState(0);
    const deleteRegionMutation = useMutation({
        mutationFn: (id) => deleteRegion(id),
        onSuccess: () => {
            queryClient.refetchQueries(['regions'], { exact: true });
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    useEffect(() => {
        if (regions) {
            const filteredList = filterRegex
                ? regions.filter(
                      ({ surname, name, patronymic, city }) =>
                          filterRegex.test(surname + name + patronymic) ||
                          filterRegex.test(city)
                  )
                : regions;
            const pages = Math.ceil(filteredList.length / 30);
            setPagesCount(pages);

            if (pages > 1) {
                setSlicedList(filteredList.slice(page * 30, page * 30 + 30));
            } else {
                setSlicedList(filteredList);
            }
        }
    }, [regions, page, filterRegex]);

    useEffect(() => {
        if (page > 0) {
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const selectPage = (selectedPage) => {
        window.scrollTo({ top: 0 });
        setPage(selectedPage);
    };

    const setFilterRegexHandler = (regex) => {
        setFilterRegex(regex);
    };

    const handleDeleteRegion = (id) => {
        deleteRegionMutation.mutate(id);
    };

    return (
        <>
            <SearchBlock
                placeholder="Поиск региона"
                setFilterRegexHandler={setFilterRegexHandler}
                delay={500}
            />

            {regionsIsLoading && <Loader />}
            {regionsError && (
                <p className="data-error">{regionsError.message}</p>
            )}

            {!regionsIsLoading && <AddNewRegion />}

            {!!slicedList?.length && (
                <ul className="list-wrapper">
                    {slicedList.map((item) => (
                        <RegionalRegionsItem
                            key={item._id}
                            item={item}
                            onDelete={handleDeleteRegion}
                        />
                    ))}
                </ul>
            )}

            {regions && !regionsIsLoading && !slicedList?.length && (
                <div className="not-found-text">Регионы не найдены</div>
            )}

            {pagesCount > 1 && (
                <ListPagesBlock
                    pagesCount={pagesCount}
                    selectPage={selectPage}
                    activePage={page}
                />
            )}
        </>
    );
};
