import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export function deleteMunicipality(municipalityID) {
    return catchAsync(async () => {
        const response = await axios.delete(`municipality/${municipalityID}`);
        return response.data;
    }, 'Ошибка удаления маниципалитета');
}
