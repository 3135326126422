import { useState } from 'react';

import { PsychologistsItemDetailStatuses } from './psychologists-list-item/PsychologistsItemDetailStatuses';
import { PsychologistsItemDetailNote } from './psychologists-list-item/PsychologistsItemDetailNote';
import { PsychologistsItemDetailUserInfo } from './psychologists-list-item/PsychologistsItemDetailUserInfo';
import { Modal } from 'components/Modal';
import { ChangeInstitutionIdModal } from './psychologists-list-item/ChangeInstitutionIdModal';
import { ChangeMunicipalityModal } from './psychologists-list-item/ChangeMunicipalityModal';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.surname
 * @param {String} item.patronymic
 * @param {String} item.email
 * @param {String} item.login
 * @param {String} item.status
 * @param {String} item.region
 * @param {String} item.city
 * @param {String} item.phone
 * @param {String} item.institution
 * @param {String} item.institutionID
 * @param {Boolean} item.isInstitutionNotFound
 * @param {String} [item.municipality]
 * @param {String} item.regionID
 * @param {String} item.birthday
 * @param {String} item.lastLogin
 * @param {String} item.note
 * @param {String} [item.dnevnikruPersonId]
 * @param {Date} item.createDate
 * @param {Date} item.updateDate
 * @param {Function} saveNote
 * @param {String} source
 */
export const RegionalPsychologistsItemDetail = ({
    item,
    saveNote,
    source = 'testu'
}) => {
    const [isEditInstitutionID, setIsEditInstitutionID] = useState(false);
    const [isEditMunicipality, setIsEditMunicipality] = useState(false);
    const { _id, status, note, institutionID, municipality, regionID } = item;

    const handleCloseModals = () => {
        setIsEditInstitutionID(false);
        setIsEditMunicipality(false);
    };

    return (
        <>
            <div className={styles.more}>
                <div className={styles.flex}>
                    <PsychologistsItemDetailUserInfo
                        item={item}
                        onEditInstitutionID={() => setIsEditInstitutionID(true)}
                        onEditMunicipality={() => setIsEditMunicipality(true)}
                    />

                    {status && (
                        <PsychologistsItemDetailStatuses
                            _id={_id}
                            status={status}
                            source={source}
                        />
                    )}
                </div>

                <PsychologistsItemDetailNote
                    _id={_id}
                    note={note}
                    saveNote={saveNote}
                />
            </div>

            {isEditInstitutionID && (
                <Modal
                    title="Изменить ID учреждения"
                    onClose={handleCloseModals}
                >
                    <ChangeInstitutionIdModal
                        psychID={_id}
                        institutionID={institutionID}
                        regionID={regionID}
                        onCloseModal={handleCloseModals}
                    />
                </Modal>
            )}

            {isEditMunicipality && (
                <Modal
                    title="Изменить Муниципалитет"
                    onClose={handleCloseModals}
                >
                    <ChangeMunicipalityModal
                        psychID={_id}
                        municipalityID={municipality}
                        regionID={regionID}
                        onCloseModal={handleCloseModals}
                    />
                </Modal>
            )}
        </>
    );
};
