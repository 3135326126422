import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ListPagesBlock } from 'components/ListPagesBlock';
import { updatePsychologist } from 'redux/actions/setPsychologistsActions';
import { PsychologistsItem } from './PsychologistsItem';
import { SearchBlock } from 'components/common/SearchBlock';

export const PsychologistsList = ({ list, source = 'testu' }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { loading } = useSelector((state) => state.app);
    const [activeItem, setActiveItem] = useState('');
    const [slicedList, setSlicedList] = useState([]);
    const [filterRegex, setFilterRegex] = useState('');
    const [pagesCount, setPagesCount] = useState(1);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (list) {
            const filteredList = filterRegex
                ? list.filter(
                      ({ surname, name, patronymic, city }) =>
                          filterRegex.test(surname + name + patronymic) ||
                          filterRegex.test(city)
                  )
                : list;
            const pages = Math.ceil(filteredList.length / 30);
            setPagesCount(pages);

            if (pages > 1) {
                setSlicedList(filteredList.slice(page * 30, page * 30 + 30));
            } else {
                setSlicedList(filteredList);
            }
        }
    }, [list, page, filterRegex]);

    useEffect(() => {
        if (page > 0) {
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const activeItemHandler = (id) => {
        if (id !== activeItem) {
            setActiveItem(id);
        } else {
            setActiveItem('');
        }
    };

    const selectPage = (selectedPage) => {
        window.scrollTo({ top: 0 });
        setPage(selectedPage);
    };

    const saveNote = (id, note) => {
        dispatch(updatePsychologist(id, { note }, 'list', source));
    };

    const setFilterRegexHandler = (regex) => {
        setFilterRegex(regex);
    };

    return (
        <>
            <SearchBlock
                placeholder="Поиск по ФИО или городу"
                setFilterRegexHandler={setFilterRegexHandler}
            />

            {!!slicedList?.length && (
                <ul className="list-wrapper">
                    {slicedList.map((item) => (
                        <PsychologistsItem
                            key={item._id}
                            item={item}
                            activeItem={activeItem}
                            activeItemHandler={activeItemHandler}
                            saveNote={saveNote}
                            source={source}
                        />
                    ))}
                </ul>
            )}

            {!loading && !slicedList?.length && (
                <div className="not-found-text">Ничего не найдено</div>
            )}

            {pagesCount > 1 && (
                <ListPagesBlock
                    pagesCount={pagesCount}
                    selectPage={selectPage}
                    activePage={page}
                />
            )}
        </>
    );
};
