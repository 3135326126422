import {
    PSYCHOLOGISTS_LIST,
    PSYCHOLOGISTS_UPDATE,
    REGIONAL_PSYCHOLOGISTS_LIST
} from './types';

const initialState = {
    list: null,
    regionalList: null
};

export const psychologistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PSYCHOLOGISTS_LIST:
            return { ...state, list: action.payload };
        case REGIONAL_PSYCHOLOGISTS_LIST:
            return { ...state, regionalList: action.payload };
        case PSYCHOLOGISTS_UPDATE: {
            const { userId, updatedUser, listType } = action.payload;
            const newList = [...state[listType]];
            const index = newList.findIndex(({ _id }) => _id === userId);

            if (index !== -1) {
                if (newList[index].status === updatedUser.status) {
                    newList.splice(index, 1, updatedUser);
                } else {
                    newList.splice(index, 1);
                }
            }

            return { ...state, [listType]: newList };
        }
        default:
            return state;
    }
};
