import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchRegionalPsychologists } from 'redux/actions/setPsychologistsActions';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { RegionalPsychologistsList } from 'components/psychologists/RegionalPsychologistsList';

const title = {
    all: 'Психологи: Все аккаунты',
    new: 'Психологи: Новые заявки',
    active: 'Психологи: Подтверждённые аккаунты',
    banned: 'Психологи: Заблокированные аккаунты',
    pilot: 'Психологи: Пилотные аккаунты'
};

export const RegionsPsychologistsListPage = ({ source = 'testu' }) => {
    const { slug } = useParams();
    const { regionalList } = useSelector((state) => state.psychologists);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRegionalPsychologists(slug, source));
    }, [slug, source, dispatch]);

    return (
        <ContentLayout title={`Региональные интеграции / ${title[slug]}`}>
            <RegionalPsychologistsList list={regionalList} source={source} />
        </ContentLayout>
    );
};
