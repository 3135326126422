import styles from './DashboardStatisticContent.module.scss';
import React from 'react';
import DashboardMainData from './DashboardMainData';
import DashboardGroupsData from './DashboardGroupsData';
import { Link } from 'react-router-dom';
import DashboardMethodsData from './DashboardMethodsData';
import ArrowLeftIcon from '../../../static/img/ic-arrowLeft.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdmin } from 'redux/actions/setAdminActions';

export const DashboardStatisticContent = () => {
    const dispatch = useDispatch();
    const {admin} = useSelector((state) => state.admin);
    
    const logoutHandler = () => {
        dispatch(logoutAdmin());
    };

    return (
        <div className={styles.dashboardStatisticContent}>
            {
                (admin?.type !== "onlyMunicipality" && admin?.type !== "onlyDash") && 
                <Link to={'/'} className={styles.backLink}>
                    <img src={ArrowLeftIcon} alt="Назад"/>
                    <p>Назад</p>
                </Link>
            }
            <div className={styles.row}>
                <h2 className={styles.heading}>Статистика по регионам</h2>
                {
                    (admin?.type === "onlyMunicipality" || admin?.type === "onlyDash")  && 
                    <button 
                        onClick={logoutHandler} 
                        className={styles.logoutButton}>
                            Выйти из аккаунта
                    </button>  
                }
            </div>
            <DashboardMainData />
            <DashboardGroupsData/>
            <DashboardMethodsData/>
            <div className={styles.showStatsByMethodBlock}>
                <Link to={'/statisticByMethods'} className={styles.showStatsByMethod}>
                    Показать статистику по методикам
                </Link>
            </div>
        </div>
    );
};
