import React from 'react';
import { useDispatch } from 'react-redux';

import { hideAlert } from 'redux/actions/setAppActions';

export const Alert = ({ type, text }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(hideAlert());
    };

    return (
        <div className="alert-wrapper" onClick={handleClick}>
            <div className={`alert ${!!type && 'alert_' + type}`}>
                {!!type && <span />}
                <p>{text}</p>
            </div>
        </div>
    );
};
