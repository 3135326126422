import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useFetchRegions } from 'hooks/useQuery/useFetchRegions';
import { useFetchInstitutionsByRegionID } from 'hooks/useQuery/useFetchInstitutionsByRegionID';
import { deleteInstitution } from 'api/institutions/deleteInstitution';
import { ListPagesBlock } from 'components/ListPagesBlock';
import { SearchBlock } from 'components/common/SearchBlock';
import { RegionalInstitutionsItem } from './RegionalInstitutionsItem';
import { Loader } from 'components/Loader';
import { AddNewInstitution } from './AddNewInstitution';
import { EditInstitutionModal } from './EditInstitutionModal';
import { showAlert } from 'redux/actions/setAppActions';

export const RegionalInstitutionsList = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { regionID } = useParams();
    const { institutions, institutionsIsLoading, institutionsError } =
        useFetchInstitutionsByRegionID(regionID);
    const { regions } = useFetchRegions();
    const { pathname } = useLocation();
    const [slicedList, setSlicedList] = useState([]);
    const [filterRegex, setFilterRegex] = useState('');
    const [pagesCount, setPagesCount] = useState(1);
    const [page, setPage] = useState(0);
    const [editingInstitution, setEditingInstitution] = useState(null);
    const deleteMunicipalityMutation = useMutation({
        mutationFn: (id) => deleteInstitution(id),
        onSuccess: () => {
            queryClient.refetchQueries(['institutions', regionID]);
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    useEffect(() => {
        if (institutions) {
            const filteredList = filterRegex
                ? institutions.filter(
                      ({ name, institutionID }) =>
                          filterRegex.test(name) ||
                          filterRegex.test(institutionID)
                  )
                : institutions;
            const pages = Math.ceil(filteredList.length / 30);
            setPagesCount(pages);

            if (pages > 1) {
                setSlicedList(filteredList.slice(page * 30, page * 30 + 30));
            } else {
                setSlicedList(filteredList);
            }
        }
    }, [institutions, page, filterRegex]);

    useEffect(() => {
        if (page > 0) {
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const selectPage = (selectedPage) => {
        window.scrollTo({ top: 0 });
        setPage(selectedPage);
    };

    const setFilterRegexHandler = (regex) => {
        setFilterRegex(regex);
    };

    const handleDelete = (id) => {
        deleteMunicipalityMutation.mutate(id);
    };

    const handleEdit = (institution) => {
        setEditingInstitution(institution);
    };

    const handleCloseEditModal = () => {
        setEditingInstitution(null);
    };

    return (
        <>
            <SearchBlock
                placeholder="Поиск по названию или идентификатору"
                setFilterRegexHandler={setFilterRegexHandler}
                delay={500}
            />

            {institutionsIsLoading && <Loader />}
            {institutionsError && (
                <p className="data-error">{institutionsError.message}</p>
            )}

            <div className="content-title">
                <h4>
                    <Link to="/regional/regions">Регионы</Link> /{' '}
                    {regions?.find(({ _id }) => _id === regionID)?.name}
                </h4>
            </div>

            {!institutionsIsLoading && (
                <AddNewInstitution regionID={regionID} />
            )}

            {editingInstitution && (
                <div className="modal">
                    <div className="modal-content">
                        <EditInstitutionModal
                            institution={editingInstitution}
                            regionID={regionID}
                            onClose={handleCloseEditModal}
                        />
                    </div>
                </div>
            )}

            {!!slicedList?.length && (
                <ul className="list-wrapper">
                    {slicedList.map((item) => (
                        <RegionalInstitutionsItem
                            key={item._id}
                            item={item}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    ))}
                </ul>
            )}

            {institutions && !institutionsIsLoading && !slicedList?.length && (
                <>
                    <hr
                        style={{
                            marginBottom: 16,
                            height: 1,
                            background: '#dfe0eb'
                        }}
                    />
                    <div className="not-found-text">Учреждения не найдены</div>
                </>
            )}

            {pagesCount > 1 && (
                <ListPagesBlock
                    pagesCount={pagesCount}
                    selectPage={selectPage}
                    activePage={page}
                />
            )}
        </>
    );
};
