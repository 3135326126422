import axios from 'axios';

import { API_URL, HR_API_URL, UNIVER_API_URL } from 'configs/common';
import {
    finishLoading,
    showAlert,
    startLoading
} from '../actions/setAppActions';
import {
    PSYCHOLOGISTS_LIST,
    PSYCHOLOGISTS_UPDATE,
    REGIONAL_PSYCHOLOGISTS_LIST
} from '../types';

const sourcesApiUrls = {
    testu: API_URL,
    hr: HR_API_URL,
    univer: UNIVER_API_URL
};

export function fetchPsychologists(type, source = 'testu') {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.get(
                `${sourcesApiUrls[source]}/api/psych/get-list/${type}`
            );
            dispatch({ type: PSYCHOLOGISTS_LIST, payload: data });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function fetchRegionalPsychologists(type, source = 'testu') {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.get(
                `${sourcesApiUrls[source]}/api/psych/regional/${type}`
            );
            dispatch({ type: REGIONAL_PSYCHOLOGISTS_LIST, payload: data });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function changePsychologistStatus(
    userId,
    status,
    listType,
    source = 'testu'
) {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.put(
                `${sourcesApiUrls[source]}/api/psych/change-status/${userId}?status=${status}`
            );

            dispatch({
                type: PSYCHOLOGISTS_UPDATE,
                payload: {
                    userId,
                    updatedUser: data,
                    listType
                }
            });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updatePsychologist(userId, body, listType, source = 'testu') {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.put(
                `${sourcesApiUrls[source]}/api/psych/update/${userId}`,
                body
            );

            dispatch({
                type: PSYCHOLOGISTS_UPDATE,
                payload: {
                    userId,
                    updatedUser: data,
                    listType
                }
            });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}

export function updateRegionalPsychologist(userId, body, source = 'testu') {
    return async (dispatch) => {
        try {
            dispatch(startLoading());
            const { data } = await axios.put(
                `${sourcesApiUrls[source]}/api/psych/update-regional/${userId}`,
                body
            );

            dispatch({
                type: PSYCHOLOGISTS_UPDATE,
                payload: {
                    userId,
                    updatedUser: data,
                    listType: 'regionalList'
                }
            });
        } catch (e) {
            const text = e.response?.data?.message || e.message;
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };
}
