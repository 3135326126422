import { useQuery } from '@tanstack/react-query';

import { fetchInstitutionsByRegionID } from 'api/institutions/fetchInstitutionsByRegionID';

/**
 * @param {string} regionID
 */
export function useFetchInstitutionsByRegionID(regionID) {
    const { data, error, isLoading } = useQuery({
        queryKey: ['institutions', regionID],
        queryFn: () => fetchInstitutionsByRegionID(regionID)
    });

    return {
        institutions: data,
        institutionsError: error,
        institutionsIsLoading: isLoading
    };
}
