import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

/**
 * @param {String} regionID
 * @param {Object} data
 * @param {String} data.name
 * @param {String} data.municipality
 *
 */
export function createInstitution(regionID, data) {
    return catchAsync(async () => {
        const response = await axios.post(`institutions/${regionID}`, data);
        return response.data;
    }, 'Ошибка создания учреждения');
}
