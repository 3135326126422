import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { Modal } from 'components/Modal';
import { showAlert } from 'redux/actions/setAppActions';
import { uploadInstitutionsExcel } from 'api/institutions/uploadInstitutionsExcel';
import styles from './AddNewInstitutionModal.module.scss';

export const UploadExcelModal = ({ regionID, onClose }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const { mutate, isLoading } = useMutation({
        mutationFn: (file) => uploadInstitutionsExcel(regionID, file),
        onSuccess: () => {
            queryClient.refetchQueries(['institutions', regionID]);
            onClose();
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (['xls', 'xlsx'].includes(fileExtension)) {
                setSelectedFile(file);
            } else {
                dispatch(
                    showAlert({
                        type: 'error',
                        text: 'Пожалуйста, выберите файл Excel (.xls или .xlsx)'
                    })
                );
                e.target.value = '';
            }
        }
    };

    const handleUpload = () => {
        if (selectedFile) {
            mutate(selectedFile);
        } else {
            dispatch(
                showAlert({
                    type: 'error',
                    text: 'Пожалуйста, выберите файл'
                })
            );
        }
    };

    return (
        <Modal title="Добавление учреждений через эксель" onClose={onClose}>
            <div className={styles.wrapper}>
                <label className="label">
                    Выберите Excel файл (.xls или .xlsx)
                </label>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=".xls,.xlsx"
                    onChange={handleFileChange}
                    className={styles.input}
                />
                <div className={styles.buttons}>
                    <button
                        className="button"
                        onClick={handleUpload}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Загрузка...' : 'Загрузить'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
