import { useState } from 'react';

import { AddNewInstitutionModal } from './AddNewInstitutionModal';
import { UploadExcelModal } from './UploadExcelModal';
import styles from './AddNewInstitution.module.scss';

export const AddNewInstitution = ({ regionID }) => {
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowUploadModal, setIsShowUploadModal] = useState(false);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.buttonsRow}>
                    <button
                        className="button"
                        onClick={() => setIsShowModal(true)}
                    >
                        + Добавить учреждение
                    </button>
                    <button
                        className="button"
                        onClick={() => setIsShowUploadModal(true)}
                    >
                        + Добавить через эксель
                    </button>
                </div>
            </div>

            {isShowModal && (
                <AddNewInstitutionModal
                    regionID={regionID}
                    onClose={() => setIsShowModal(false)}
                />
            )}

            {isShowUploadModal && (
                <UploadExcelModal
                    regionID={regionID}
                    onClose={() => setIsShowUploadModal(false)}
                />
            )}
        </>
    );
};
