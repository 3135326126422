import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export function deleteRegion(regionID) {
    return catchAsync(async () => {
        const response = await axios.delete(`regions/${regionID}`);
        return response.data;
    }, 'Ошибка удаления региона');
}
