import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export function deleteInstitution(institutionID) {
    return catchAsync(async () => {
        const response = await axios.delete(`institutions/${institutionID}`);
        return response.data;
    }, 'Ошибка удаления учреждения');
}
