import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

/**
 * @param {String} institutionID
 * @param {Object} data
 * @param {String} data.name
 * @param {String} data.municipality
 *
 */
export function updateInstitution(institutionID, data) {
    return catchAsync(async () => {
        const response = await axios.put(`institutions/${institutionID}`, data);
        return response.data;
    }, 'Ошибка редактирования учреждения');
}
