export function getMunicipalityName(id, municipalities) {
    if (!municipalities) {
        return 'Муниципалитет не найден';
    }

    const findedMunicipality = municipalities.find(
        (municipality) => municipality._id === id
    );

    return findedMunicipality?.name || 'Муниципалитет не найден';
}
