import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useFetchRegions } from 'hooks/useQuery/useFetchRegions';
import { useFetchMunicipalitiesByRegionID } from 'hooks/useQuery/useFetchMunicipalitiesByRegionID';
import { deleteMunicipality } from 'api/municipalities/deleteMunicipality';
import { showAlert } from 'redux/actions/setAppActions';
import { ListPagesBlock } from 'components/ListPagesBlock';
import { SearchBlock } from 'components/common/SearchBlock';
import { RegionalMunicipalitiesItem } from './RegionalMunicipalitiesItem';
import { Loader } from 'components/Loader';
import { AddNewMunicipality } from './AddNewMunicipality';

export const RegionalMunicipalitiesList = () => {
    const { regionID } = useParams();
    const { municipalities, municipalitiesIsLoading, municipalitiesError } =
        useFetchMunicipalitiesByRegionID(regionID);
    const { regions } = useFetchRegions();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const [slicedList, setSlicedList] = useState([]);
    const [filterRegex, setFilterRegex] = useState('');
    const [pagesCount, setPagesCount] = useState(1);
    const [page, setPage] = useState(0);
    const deleteMunicipalityMutation = useMutation({
        mutationFn: (id) => deleteMunicipality(id),
        onSuccess: () => {
            queryClient.refetchQueries(['municipalities'], { exact: true });
            queryClient.refetchQueries(['municipalities', regionID]);
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    useEffect(() => {
        if (municipalities) {
            const filteredList = filterRegex
                ? municipalities.filter(({ name }) => filterRegex.test(name))
                : municipalities;
            const pages = Math.ceil(filteredList.length / 30);
            setPagesCount(pages);

            if (pages > 1) {
                setSlicedList(filteredList.slice(page * 30, page * 30 + 30));
            } else {
                setSlicedList(filteredList);
            }
        }
    }, [municipalities, page, filterRegex]);

    useEffect(() => {
        if (page > 0) {
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const selectPage = (selectedPage) => {
        window.scrollTo({ top: 0 });
        setPage(selectedPage);
    };

    const setFilterRegexHandler = (regex) => {
        setFilterRegex(regex);
    };

    const handleDelete = (id) => {
        deleteMunicipalityMutation.mutate(id);
    };

    return (
        <>
            <SearchBlock
                placeholder="Поиск муниципалитета"
                setFilterRegexHandler={setFilterRegexHandler}
                delay={500}
            />

            {municipalitiesIsLoading && <Loader />}
            {municipalitiesError && (
                <p className="data-error">{municipalitiesError.message}</p>
            )}

            <div className="content-title">
                <h4>
                    <Link to="/regional/regions">Регионы</Link> /{' '}
                    {regions?.find(({ _id }) => _id === regionID)?.name}
                </h4>
            </div>

            {!municipalitiesIsLoading && (
                <AddNewMunicipality regionID={regionID} />
            )}

            {!!slicedList?.length && (
                <ul className="list-wrapper">
                    {slicedList.map((item) => (
                        <RegionalMunicipalitiesItem
                            key={item._id}
                            item={item}
                            onDelete={handleDelete}
                        />
                    ))}
                </ul>
            )}

            {municipalities &&
                !municipalitiesIsLoading &&
                !slicedList?.length && (
                    <>
                        <hr
                            style={{
                                marginBottom: 16,
                                height: 1,
                                background: '#dfe0eb'
                            }}
                        />
                        <div className="not-found-text">
                            Муниципалитеты не найдены
                        </div>
                    </>
                )}

            {pagesCount > 1 && (
                <ListPagesBlock
                    pagesCount={pagesCount}
                    selectPage={selectPage}
                    activePage={page}
                />
            )}
        </>
    );
};
