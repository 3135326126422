import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { showAlert } from 'redux/actions/setAppActions';
import { reactSelectStyles } from 'static/styles/reactSelectStyles';
import { useFetchMunicipalitiesByRegionID } from 'hooks/useQuery/useFetchMunicipalitiesByRegionID';
import { classNames } from 'helpers/classNames';
import { createInstitution } from 'api/institutions/createInstitution';
import { Modal } from 'components/Modal';
import styles from './AddNewInstitutionModal.module.scss';

export const AddNewInstitutionModal = ({ regionID, onClose }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [input, setInput] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [municipalityOptions, setMunicipalityOptions] = useState([]);
    const { municipalities } = useFetchMunicipalitiesByRegionID(regionID);
    const { mutate } = useMutation({
        mutationFn: (data) => createInstitution(regionID, data),
        onSuccess: () => {
            queryClient.refetchQueries(['institutions', regionID]);
            onClose();
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    useEffect(() => {
        if (municipalities) {
            setMunicipalityOptions(
                municipalities.map(({ _id, name }) => ({
                    value: _id,
                    label: name
                }))
            );
        }
    }, [municipalities]);

    const handleSelect = (e) => {
        setSelectedOption(e);
    };

    const handleSave = () => {
        const name = input.trim();

        if (name) {
            const data = {
                name,
                region: regionID
            };

            if (selectedOption?.value) {
                data.municipality = selectedOption.value;
            }

            mutate(data);
        }
    };

    return (
        <Modal title="Добавление учреждения" onClose={onClose}>
            <div className={styles.wrapper}>
                <label htmlFor="institution-name" className="label">
                    Название учреждения
                </label>
                <input
                    className={classNames(['input', styles.input])}
                    id="institution-name"
                    type="text"
                    name="institution-name"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                />

                <label className="label">Муниципалитет</label>
                <Select
                    value={selectedOption}
                    onChange={handleSelect}
                    options={municipalityOptions}
                    placeholder="Выберите муниципалитет"
                    noOptionsMessage={() => 'Муниципалитеты не найдены'}
                    styles={reactSelectStyles}
                />

                <div className={styles.buttons} onClick={handleSave}>
                    <button className="button">Добавить</button>
                </div>
            </div>
        </Modal>
    );
};
