/**
 * Функция для соединения имен классов с возможностью простого отображения динамических классов
 *
 * @param {string|string[]} classes - Строка или массив строк с классами
 * @param {object} [dynamic] - Динамический класс. Формат { class: Boolean }
 */

export const classNames = (classes, dynamic = {}) => {
    const classesList = typeof classes === 'string' ? [classes] : [...classes];
    const dynamicClassesList = Object.entries(dynamic)
        .filter(([_, value]) => Boolean(value))
        .map(([className]) => className);

    return [...classesList, ...dynamicClassesList].join(' ').trim();
};
