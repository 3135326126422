import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export function fetchMunicipalities() {
    return catchAsync(async () => {
        const response = await axios.get('municipality');
        return response.data;
    });
}
