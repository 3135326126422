import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Loader } from 'components/Loader';
import { reactSelectStyles } from 'static/styles/reactSelectStyles';
import { updateRegionalPsychologist } from 'redux/actions/setPsychologistsActions';
import { useFetchMunicipalitiesByRegionID } from 'hooks/useQuery/useFetchMunicipalitiesByRegionID';
import styles from './ChangeInfoModal.module.scss';

/**
 * @param {String} psychID
 * @param {String} municipalityID
 * @param {String} regionID
 * @param {Function} onCloseModal
 */
export const ChangeMunicipalityModal = ({
    psychID,
    municipalityID,
    regionID,
    onCloseModal
}) => {
    const dispatch = useDispatch();
    const { municipalities, municipalitiesError, municipalitiesIsLoading } =
        useFetchMunicipalitiesByRegionID(regionID);
    const [selectedOption, setSelectedOption] = useState(null);
    const [municipalityOptions, setMunicipalityOptions] = useState([]);

    useEffect(() => {
        if (municipalityID && municipalities) {
            const findedMunicipality = municipalities.find(
                ({ _id }) => _id === municipalityID
            );

            if (findedMunicipality) {
                setSelectedOption({
                    value: findedMunicipality._id,
                    label: findedMunicipality.name
                });
            }
        }
    }, [municipalityID, municipalities]);

    useEffect(() => {
        if (municipalities) {
            setMunicipalityOptions(
                municipalities.map(({ _id, name }) => ({
                    value: _id,
                    label: name
                }))
            );
        }
    }, [municipalities]);

    const handleSelect = (e) => {
        setSelectedOption(e);
    };

    const handleSave = () => {
        if (selectedOption) {
            if (selectedOption.value !== municipalityID) {
                dispatch(
                    updateRegionalPsychologist(psychID, {
                        municipality: selectedOption.value
                    })
                );
            }
            onCloseModal();
        }
    };

    return (
        <div className={styles.wrapper}>
            {municipalitiesIsLoading && <Loader />}
            {municipalitiesError && (
                <div className="data-error">{municipalitiesError.message}</div>
            )}

            {!municipalitiesIsLoading && !municipalitiesError && (
                <>
                    <Select
                        value={selectedOption}
                        onChange={handleSelect}
                        options={municipalityOptions}
                        placeholder="Выберите муниципалитет"
                        noOptionsMessage={() => 'Муниципалитеты не найдены'}
                        styles={reactSelectStyles}
                    />

                    <div className={styles.buttons} onClick={handleSave}>
                        <button className="button">Сохранить</button>
                    </div>
                </>
            )}
        </div>
    );
};
