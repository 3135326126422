import { NavLink } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import { IS_YANAO } from 'configs/common';

export const Sidebar = () => {
    return (
        <div className={styles.sidebar}>
            <h3 className={styles.title}>Навигация</h3>
            <nav className={styles.nav}>
                <NavLink
                    to="/"
                    className={styles.navItem}
                    exact
                    activeClassName="active"
                >
                    Главная
                </NavLink>

                <p className={styles.navItem}>Психологи</p>
                <ul>
                    <NavLink to="/psych/new" activeClassName="active">
                        <li>Новые заявки</li>
                    </NavLink>
                    <NavLink to="/psych/active" activeClassName="active">
                        <li>Подтверждённые</li>
                    </NavLink>
                    <NavLink to="/psych/pilot" activeClassName="active">
                        <li>Пилотные</li>
                    </NavLink>
                    <NavLink to="/psych/banned" activeClassName="active">
                        <li>Заблокированные</li>
                    </NavLink>
                </ul>

                {!IS_YANAO && (
                    <>
                        <p className={styles.navItem}>
                            Региональные интеграции
                        </p>
                        <ul>
                            <NavLink
                                to="/regional/regions"
                                activeClassName="active"
                            >
                                <li>Регионы</li>
                            </NavLink>

                            <li>Психологи</li>

                            <ul className={styles.subNav}>
                                <NavLink
                                    to="/psych/regional/new"
                                    activeClassName="active"
                                >
                                    <li>Новые заявки</li>
                                </NavLink>
                                <NavLink
                                    to="/psych/regional/active"
                                    activeClassName="active"
                                >
                                    <li>Подтверждённые</li>
                                </NavLink>
                                <NavLink
                                    to="/psych/regional/pilot"
                                    activeClassName="active"
                                >
                                    <li>Пилотные</li>
                                </NavLink>
                                <NavLink
                                    to="/psych/regional/banned"
                                    activeClassName="active"
                                >
                                    <li>Заблокированные</li>
                                </NavLink>
                            </ul>
                        </ul>
                    </>
                )}

                <p className={styles.navItem}>Заявки</p>
                <ul>
                    <NavLink to="/requests/list" activeClassName="active">
                        <li>Список</li>
                    </NavLink>
                </ul>

                <p className={styles.navItem}>Отзывы</p>
                <ul>
                    <NavLink to="/comments/suggestion" activeClassName="active">
                        <li>Предложения</li>
                    </NavLink>
                    <NavLink to="/comments/problem" activeClassName="active">
                        <li>Технические проблемы</li>
                    </NavLink>
                </ul>

                <p className={styles.navItem}>Статистика</p>
                <ul>
                    <NavLink to="/statisticDashboard" activeClassName="active">
                        <li>Дэшборд</li>
                    </NavLink>
                    <NavLink to="/statistic" activeClassName="active">
                        <li>Общая</li>
                    </NavLink>
                </ul>

                <p className={styles.navItem}>Телеграм</p>
                <ul>
                    <NavLink to="/telegram/psych" activeClassName="active">
                        <li>Психологи</li>
                    </NavLink>
                    <NavLink to="/telegram/pupil" activeClassName="active">
                        <li>Ученики</li>
                    </NavLink>
                </ul>

                <p className={styles.navItem}>Администраторы</p>
                <ul>
                    <NavLink to="/admin/list" activeClassName="active">
                        <li>Список</li>
                    </NavLink>
                </ul>

                <p className={`${styles.navItem} ${styles.newRow}`}>Hr</p>
                <ul>
                    <NavLink to="/hr/psych/new" activeClassName="active">
                        <li>Новые заявки</li>
                    </NavLink>
                    <NavLink to="/hr/psych/active" activeClassName="active">
                        <li>Подтверждённые</li>
                    </NavLink>
                    <NavLink to="/hr/psych/pilot" activeClassName="active">
                        <li>Пилотные</li>
                    </NavLink>
                    <NavLink to="/hr/psych/banned" activeClassName="active">
                        <li>Заблокированные</li>
                    </NavLink>
                </ul>

                <p className={`${styles.navItem} ${styles.newRow}`}>Univer</p>
                <ul>
                    <NavLink to="/univer/psych/new" activeClassName="active">
                        <li>Новые заявки</li>
                    </NavLink>
                    <NavLink to="/univer/psych/active" activeClassName="active">
                        <li>Подтверждённые</li>
                    </NavLink>
                    <NavLink to="/univer/psych/pilot" activeClassName="active">
                        <li>Пилотные</li>
                    </NavLink>
                    <NavLink to="/univer/psych/banned" activeClassName="active">
                        <li>Заблокированные</li>
                    </NavLink>
                </ul>
            </nav>
        </div>
    );
};
