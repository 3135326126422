import { useState } from 'react';

import { Modal } from 'components/Modal';
import { AddNewRegionModal } from './AddNewRegionModal';
import styles from './AddNewRegion.module.scss';

export const AddNewRegion = () => {
    const [isShowModal, setIsShowModal] = useState(false);

    const handleCloseModal = () => {
        setIsShowModal(false);
    };

    return (
        <>
            <div className={styles.wrapper}>
                <button className="button" onClick={() => setIsShowModal(true)}>
                    + Добавить регион
                </button>
            </div>

            {isShowModal && (
                <Modal title="Добавление региона" onClose={handleCloseModal}>
                    <AddNewRegionModal onClose={handleCloseModal} />
                </Modal>
            )}
        </>
    );
};
