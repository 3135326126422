import { useFetchMunicipalities } from 'hooks/useQuery/useFetchMunicipalities';
import { getDate, getDateTime } from 'helpers/dates';
import { classNames } from 'helpers/classNames';
import { getMunicipalityName } from 'helpers/getMunicipalityName';
import editIcon from 'static/img/edit.svg';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {Object} item
 * @param {String} item._id
 * @param {String} item.name
 * @param {String} item.surname
 * @param {String} item.patronymic
 * @param {String} item.email
 * @param {String} item.login
 * @param {String} item.status
 * @param {String} item.region
 * @param {String} item.city
 * @param {String} item.phone
 * @param {String} item.institution
 * @param {String} item.institutionID
 * @param {Boolean} item.isInstitutionNotFound
 * @param {String} [item.municipality]
 * @param {String} item.regionID
 * @param {String} item.birthday
 * @param {String} item.lastLogin
 * @param {String} item.note
 * @param {String} [item.dnevnikruPersonId]
 * @param {Date} item.createDate
 * @param {Date} item.updateDate
 * @param {Function} onEditInstitutionID
 * @param {Function} onEditMunicipality
 */
export const PsychologistsItemDetailUserInfo = ({
    item,
    onEditInstitutionID,
    onEditMunicipality
}) => {
    const { municipalities } = useFetchMunicipalities();
    const {
        name,
        surname,
        patronymic,
        email,
        login,
        city,
        region,
        phone,
        institution,
        institutionID,
        municipality,
        institutionType,
        birthday,
        lastLogin,
        dnevnikruPersonId
    } = item;

    return (
        <div className={styles.userInfo}>
            {name && surname && (
                <div>
                    <span>ФИО:</span> {surname} {name} {patronymic}
                </div>
            )}

            <div>
                <span>Эл. почта:</span> {email}
            </div>
            {phone && (
                <div>
                    <span>Номер телефона:</span>{' '}
                    <span className={styles.letterSpacing}>{phone}</span>
                </div>
            )}
            {login && (
                <div>
                    <span>Логин:</span> {login}
                </div>
            )}
            {birthday && (
                <div>
                    <span>Дата рождения:</span> {getDate(birthday)}
                </div>
            )}
            {region && (
                <div>
                    <span>Регион:</span> {region}
                </div>
            )}
            {city && (
                <div>
                    <span>Город:</span> {city}
                </div>
            )}
            {institutionType && (
                <div>
                    <span>Тип учреждения:</span> {institutionType}
                </div>
            )}
            {institution && (
                <div>
                    <span>Название учреждения:</span> {institution}
                </div>
            )}
            <div
                className={classNames(styles.flexRow, {
                    [styles.warnText]: !institutionID
                })}
            >
                <div>
                    <span>ID учреждения:</span> {institutionID || 'Не указан'}
                </div>
                <button
                    className={styles.editIcon}
                    onClick={onEditInstitutionID}
                >
                    <img src={editIcon} alt="Редактировать" />
                </button>
            </div>
            <div
                className={classNames(styles.flexRow, {
                    [styles.warnText]: !municipality
                })}
            >
                <div>
                    <span>Муниципалитет:</span>{' '}
                    {municipality
                        ? getMunicipalityName(municipality, municipalities)
                        : 'Не указан'}
                </div>
                <button
                    className={styles.editIcon}
                    onClick={onEditMunicipality}
                >
                    <img src={editIcon} alt="Редактировать" />
                </button>
            </div>
            <div>
                <span>Дата последнего входа в ЛК:</span>{' '}
                {getDateTime(lastLogin)}
            </div>

            {dnevnikruPersonId && (
                <div>
                    <span>ID в Дневник.ру:</span> {dnevnikruPersonId}
                </div>
            )}
        </div>
    );
};
