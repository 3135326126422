import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { useFetchInstitutionsByRegionID } from 'hooks/useQuery/useFetchInstitutionsByRegionID';
import { Loader } from 'components/Loader';
import { reactSelectStyles } from 'static/styles/reactSelectStyles';
import { updateRegionalPsychologist } from 'redux/actions/setPsychologistsActions';
import styles from './ChangeInfoModal.module.scss';

/**
 * @param {String} psychID
 * @param {String} institutionID
 * @param {String} regionID
 * @param {Function} onCloseModal
 */
export const ChangeInstitutionIdModal = ({
    psychID,
    institutionID,
    regionID,
    onCloseModal
}) => {
    const dispatch = useDispatch();
    const { institutions, institutionsError, institutionsIsLoading } =
        useFetchInstitutionsByRegionID(regionID);
    const [selectedOption, setSelectedOption] = useState(null);
    const [institutionOptions, setInstitutionOptions] = useState([]);

    useEffect(() => {
        if (institutionID) {
            setSelectedOption({
                value: institutionID,
                label: institutionID
            });
        }
    }, [institutionID]);

    useEffect(() => {
        if (institutions) {
            setInstitutionOptions(
                institutions.map(({ institutionID }) => ({
                    value: institutionID,
                    label: institutionID
                }))
            );
        }
    }, [institutions]);

    const selectRegionHandler = (e) => {
        setSelectedOption(e);
    };

    const handleSave = () => {
        if (selectedOption) {
            if (selectedOption.value !== institutionID) {
                dispatch(
                    updateRegionalPsychologist(psychID, {
                        institutionID: selectedOption.value
                    })
                );
            }
            onCloseModal();
        }
    };

    return (
        <div className={styles.wrapper}>
            {institutionsIsLoading && <Loader />}
            {institutionsError && (
                <div className="data-error">{institutionsError.message}</div>
            )}

            {!institutionsIsLoading && !institutionsError && (
                <>
                    <Select
                        value={selectedOption}
                        onChange={selectRegionHandler}
                        options={institutionOptions}
                        placeholder="Выберите ID учреждения"
                        noOptionsMessage={() => 'Учреждения не найдены'}
                        styles={reactSelectStyles}
                    />

                    <div className={styles.buttons} onClick={handleSave}>
                        <button className="button">Сохранить</button>
                    </div>
                </>
            )}
        </div>
    );
};
