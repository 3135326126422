import axios from 'axios';

import { catchAsync } from 'utils/catchAsync';

export function createMunicipality(data) {
    return catchAsync(async () => {
        const response = await axios.post('municipality', data);
        return response.data;
    }, 'Ошибка создания муниципалитета');
}
