import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { showAlert } from 'redux/actions/setAppActions';
import { createMunicipality } from 'api/municipalities/createMunicipality';
import { classNames } from 'helpers/classNames';
import styles from './AddNewMunicipalityModal.module.scss';

export const AddNewMunicipalityModal = ({ regionID, onClose }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [input, setInput] = useState('');
    const { mutate, isLoading } = useMutation({
        mutationFn: (data) => createMunicipality(data),
        onSuccess: () => {
            queryClient.refetchQueries(['municipalities'], { exact: true });
            queryClient.refetchQueries(['municipalities', regionID]);
            onClose();
        },
        onError: (error) => {
            dispatch(showAlert({ type: 'error', text: error.message }));
        }
    });

    const handleSave = () => {
        const value = input.trim();

        if (value && !isLoading) {
            mutate({ name: value, region: regionID });
        }
    };

    return (
        <div className={styles.wrapper}>
            <label htmlFor="institution-name" className="label">
                Название муниципалитета
            </label>
            <input
                className={classNames(['input', styles.input])}
                id="municipality-name"
                type="text"
                name="municipality-name"
                value={input}
                onChange={(e) => setInput(e.target.value)}
            />

            <div className={styles.buttons}>
                <button
                    className="button"
                    onClick={handleSave}
                    disabled={isLoading}
                >
                    Добавить
                </button>
            </div>
        </div>
    );
};
