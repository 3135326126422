import { useState } from 'react';

import { Modal } from 'components/Modal';
import { AddNewMunicipalityModal } from './AddNewMunicipalityModal';
import styles from './AddNewMunicipality.module.scss';

export const AddNewMunicipality = ({ regionID }) => {
    const [isShowModal, setIsShowModal] = useState(false);

    const handleCloseModal = () => {
        setIsShowModal(false);
    };

    return (
        <>
            <div className={styles.wrapper}>
                <button className="button" onClick={() => setIsShowModal(true)}>
                    + Добавить муниципалитет
                </button>
            </div>

            {isShowModal && (
                <Modal
                    title="Добавление муниципалитета"
                    onClose={handleCloseModal}
                >
                    <AddNewMunicipalityModal
                        regionID={regionID}
                        onClose={handleCloseModal}
                    />
                </Modal>
            )}
        </>
    );
};
