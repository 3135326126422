import { useDispatch, useSelector } from 'react-redux';

import { changePsychologistStatus } from 'redux/actions/setPsychologistsActions';
import styles from 'static/scss/ListsItem.module.scss';

/**
 * @param {String} _id
 * @param {String} status
 * @param {String} source
 */
export const PsychologistsItemDetailStatuses = ({ _id, status, source }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.app);

    const onClickHandler = (e) => {
        const newStatus = e.target.getAttribute('data-status');

        dispatch(
            changePsychologistStatus(_id, newStatus, 'regionalList', source)
        );
    };

    return (
        <div className={styles.buttons}>
            {['New', 'Pilot'].includes(status) && (
                <button
                    className="button"
                    data-status="Active"
                    onClick={onClickHandler}
                    disabled={loading}
                >
                    Подтвердить
                </button>
            )}
            {status === 'New' && (
                <button
                    className="button"
                    data-status="Pilot"
                    onClick={onClickHandler}
                    disabled={loading}
                >
                    Пилот
                </button>
            )}
            {['Active', 'New', 'Pilot'].includes(status) && (
                <button
                    className="button"
                    data-status="Banned"
                    onClick={onClickHandler}
                    disabled={loading}
                >
                    Заблокировать
                </button>
            )}
            {status === 'Banned' && (
                <button
                    className="button"
                    data-status="Active"
                    onClick={onClickHandler}
                    disabled={loading}
                >
                    Разблокировать
                </button>
            )}
        </div>
    );
};
