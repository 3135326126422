import { useQuery } from '@tanstack/react-query';

import { fetchMunicipalities } from 'api/municipalities/fetchMunicipalities';

export function useFetchMunicipalities() {
    const { data, error, isLoading } = useQuery({
        queryKey: ['municipalities'],
        queryFn: () => fetchMunicipalities()
    });

    return {
        municipalities: data,
        municipalitiesError: error,
        municipalitiesIsLoading: isLoading
    };
}
